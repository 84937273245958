<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { authComputed } from "@/state/helpers";
import JsonExcel from "vue-json-excel";
import store from "@/state/store";
export default {
  beforeRouteEnter(to, from, next) {
    const userType = store.getters["auth/getUser"].user_type;
    if (userType === 1 || userType === 3) {
      next();
    } else {
      alert("You are not allowed to access this page. You will be redirected.");
      next("/");
    }
  },
  page: {
    title: "Self-assessment",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    ...authComputed,
  },
  components: {
    Layout,
    PageHeader,
    JsonExcel
  },
  mounted() {
    this.refreshTable();
  },
  methods: {
    refreshTable() {
      let stage = this.$route.params.stage;
      let location = this.$route.params.location;
      let fy = this.$route.params.fy;
      this.stage = stage;
      this.fy = fy;
      this.location = location
      let data = {
       // stage: parseInt(stage),
        gs_id:parseInt(stage),
        fy: fy,
        location: parseInt(location),
      };
      const url = `${this.ROUTES.selfAssessment}/self-assessment-tracking-report`;
      this.resultStatus = true;
      this.apiPost(url,data, "Generate Self-assessment Report").then(
        (res) => {
          //let min = 3;
          const { assessments, locationName, stage, counter } = res.data;
          let maxNumOfGoals = 0;
          this.stage = stage;
          this.counter = counter;
          let columns = [];
          if((parseInt(this.stage) === 1 ) || (parseInt(this.stage) === 2 )){ //Beginning of year OR mid-year
            assessments.map((assess)=>{
              columns.push(parseInt(assess.self_assessment.length));
              maxNumOfGoals = Math.max.apply(null, columns);
            });
            //console.log(maxNumOfGoals);
            for(let i = 1; i<= maxNumOfGoals; i++){
              if (parseInt(this.stage) === 1) {
                const newFields = {
                  key: "goal_"+i,
                  label: "Goal "+i,
                  sortable: false
                };
                this.fields.push(newFields)
              }else if(parseInt(this.stage) === 2){

                const goal = {
                  key: "goal_"+i,
                  label: "Goal "+i,
                  sortable: false
                };
                const update = {
                  key: "update_"+i,
                  label: "Update "+i,
                  sortable: false
                };
                const accomplishment = {
                  key: "accomplishment_"+i,
                  label: "Accomplishment "+i,
                  sortable: false
                };
                const challenge = {
                  key: "challenge_"+i,
                  label: "Challenge "+i,
                  sortable: false
                };
                const support = {
                  key: "support_needed_"+i,
                  label: "Support Needed "+i,
                  sortable: false
                };
                const next_step = {
                  key: "next_steps_"+i,
                  label: "Next Steps "+i,
                  sortable: false
                };
                this.fields.push(goal,update, accomplishment, challenge, support, next_step)
              }

            }

            assessments.map((assess, index)=>{
              const assessmentData = {
                sn:++index,
                t7:assess.employee.emp_unique_id,
                d7:assess.employee.emp_d7 || '',
                employee:`${assess.employee?.emp_first_name} ${assess.employee?.emp_last_name}  - ${assess.employee?.emp_unique_id}`,
                jobTitle: assess.jobrole?.job_role || '-',
                department: `${assess.employee.sector?.department_name || ""}  (${
                  assess.employee.sector?.d_t3_code || ""
                })`,
                supervisor:`${assess.supervisor?.emp_first_name} ${assess.supervisor?.emp_last_name}`,
                location: assess.employee?.location.location_name || '',
                //status:assess.sam_status,
                status:parseInt(assess.sam_status) === 0 ? 'Pending Approval' : 'Approved',
                locationId:location,
                empId:assess.employee.emp_id,
                fy:this.fy,
                stage:this.stage,
              };
              assess.self_assessment.map((as, index)=>{
                assessmentData['goal_'+(index+1)] = as.sa_comment.replace(/(<([^>]+)>)/gi, "") || '-';
              })
              if(parseInt(this.stage) === 2 ){ // mid year
                assess.self_assessment.map((as, index)=>{
                  //assessmentData['goal_'+(index+1)] = as.sa_comment.replace(/(<([^>]+)>)/gi, "") || '-';
                  assessmentData['update_'+(index+1)] = as.sa_update?.replace(/(<([^>]+)>)/gi, "") || '-';
                  assessmentData['accomplishment_'+(index+1)] = as.sa_accomplishment?.replace(/(<([^>]+)>)/gi, "") || '-';
                  assessmentData['challenge_'+(index+1)] = as.sa_challenges?.replace(/(<([^>]+)>)/gi, "") || '-';
                  assessmentData['support_needed_'+(index+1)] = as.sa_support_needed?.replace(/(<([^>]+)>)/gi, "") || '-';
                  assessmentData['next_steps_'+(index+1)] = as.sa_next_steps?.replace(/(<([^>]+)>)/gi, "") || '-';
                })
              }
              this.assessments.push(assessmentData)
            });




          } //end of beginning of year OR mid-year checking

          if(parseInt(this.stage) === 3){
              //set table headers
                const strength = {
                  key: "strength_",
                  label: "Strength ",
                  sortable: false
                };
                const growth_area = {
                  key: "growth_area_",
                  label: "Growth Area ",
                  sortable: false
                };
                const rating = {
                  key: "rating_",
                  label: "Rating ",
                  sortable: false
                };
                const checking_questions = {
                  key: "checking_questions_",
                  label: "Checking Questions ",
                  sortable: false
                };
                const answer_toCQ = {
                  key: "answer_to_checkin_question_",
                  label: "Answer to Checking Question ",
                  sortable: false
                };
                const additional_comments = {
                  key: "additional_comments_",
                  label: "Additional Comments ",
                  sortable: false
                };
                const discussion_held_on = {
                  key: "discussion_held_on_",
                  label: "Discussion Held On ",
                  sortable: false
                };
                const date_of_approval = {
                  key: "date_of_approval_",
                  label: "Date of Approval ",
                  sortable: false
                };
                this.fields.push(strength,growth_area, rating, checking_questions, answer_toCQ, additional_comments, discussion_held_on,date_of_approval)

            assessments.map((assess, index)=>{
              const assessmentData = {
                sn:++index,
                t7:assess.selfAssessment.employee?.emp_unique_id || '',
                d7:assess.selfAssessment.employee?.emp_d7 || '',
                employee:`${assess.selfAssessment.employee?.emp_first_name} ${assess.selfAssessment.employee?.emp_last_name}  - ${assess.selfAssessment.employee?.emp_unique_id}`,
                jobTitle: assess.jobrole?.job_role || '-',
                department: `${assess.selfAssessment.employee?.sector?.department_name || ""}  (${
                  assess.selfAssessment.employee?.sector?.d_t3_code || ""
                })`,
                supervisor:`${assess.supervisor?.emp_first_name} ${assess.supervisor?.emp_last_name}`,
                location: assess.selfAssessment.employee?.location.location_name || '',
                //status:assess.sam_status,
                status:parseInt(assess.eysr_status) === 0 ? 'Pending Approval' : 'Approved',
                locationId:location,
                empId:assess.selfAssessment.employee?.emp_id,
                fy:this.fy,
                stage:this.stage,
              };
                assessmentData['strength_'] = assess.eysr_strength?.replace(/(<([^>]+)>)/gi, "") || '-';
                assessmentData['growth_area_'] = assess?.eysr_growth?.replace(/(<([^>]+)>)/gi, "") || '-';
                assessmentData['rating_'] = assess.rating.rating_name?.replace(/(<([^>]+)>)/gi, "") || '-';
                assess.selfAssessment?.end_year_response.map(eyr=>{
                  assessmentData['checking_questions_'] = eyr.eyr_goal?.replace(/(<([^>]+)>)/gi, "") || '-';
                  assessmentData['answer_to_checkin_question_'] = eyr.eyr_response?.replace(/(<([^>]+)>)/gi, "") || '-';
                })
                assessmentData['additional_comments_'] = assess.eysr_additional_comment?.replace(/(<([^>]+)>)/gi, "") || '-';
                assessmentData['discussion_held_on_'] = new Date(assess.selfAssessment?.sam_discussion_held_on).toLocaleDateString() || '-';
                assessmentData['date_of_approval_'] = new Date(assess.createdAt).toLocaleDateString() || '-';

              this.assessments.push(assessmentData)
            });
          }



          this.locationName = locationName;
          this.filtered = this.assessments;
          this.totalRows = this.assessments.length;
          this.fields.forEach((field) => {
            let key = field.key;
            //console.log(field);
            if (key === "sn") {
              this.jsonFields["S/N"] = key;
            } else if (key === "t7") {
              this.jsonFields["T7"] = key;
            } else if (key === "d7") {
              this.jsonFields["D7"] = key;
            } else if (key === "employee") {
              this.jsonFields["Name"] = key;
            } else if (key === "position") {
              this.jsonFields["Position"] = key;
            } else if (key === "location") {
              this.jsonFields["Location(T6)"] = key;
            } else if (key === "sector") {
              this.jsonFields["Sector"] = key;
              //console.log(`Key ${key} - u`)
            } else if (key === "jobTitle") {
              this.jsonFields["Job Title"] = key;
            } else if (key === "status") {
              this.jsonFields["Status"] = key;
            }else {
              this.jsonFields[key.toUpperCase()] = key;
            }
          });
          this.counter = 0;
          this.resultStatus = false;
        }
      );
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    /*selectRow(row) {
      console.log(row)
      row = row[0];
      const stage = `${row.stage}`;
      const fy = `${row.fy}`;
      const location = row.location;
      this.$router.push({
        name: "self-assessment-tracker-report",
        params: { stage, fy, location },
      });
    }*/
  },
  data() {
    return {
      title: "Self-assessment Report",
      items: [
        {
          text: "IHUMANE",
        },
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Self-assessment Report",
          active: true,
        },
      ],
      counter:0,
      resultStatus:false,
      assessments: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "sn",
      sortDesc: false,
      loc: null,
      location: null,
      locationName: null,
      fy:null,
      stage:null,
      month: null,
      year: null,
      selectedPeriod: null,
      jsonFields: {},
      filtered: [],

      fields: [
        { key: "sn", label: "S/n", sortable: true },
        { key: "t7", label: "T7", sortable: true },
        { key: "d7", label: "D7", sortable: true },
        { key: "employee", label: "Name", sortable: true },
        { key: "jobTitle", label: "Job Title", sortable: true},
        { key: "supervisor", label: "Supervisor", sortable: true },
        { key: "department", label: "Sector", sortable: false },
        { key: "location", label: "Location(T6)", sortable: false,},
        { key: "status", label: "Status", sortable: true },
      ],

    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <scale-loader v-if="apiBusy" />
    <div v-else class="row">
      <div class="col-12">

        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-md-12 col-lg-12 mb-5">
                <div class="btn-group">
                  <b-button
                    class="btn btn-secondary"
                    @click="$router.push({ name: 'reports' })"
                  >
                    <i class="mdi mdi-step-backward mr-2"></i>
                    Go Back
                  </b-button>
                  <button v-if="parseInt(stage) === 1" class="font-size-12 btn btn-success float-right">
                    <JsonExcel
                      style="cursor: pointer"
                      :data="filtered"
                      :fields="jsonFields"
                      :name="`Beginning_Of_Year_Self_Assessment_Report(${fy}_${locationName}).xls`"
                    >
                      Export to Excel
                    </JsonExcel>
                  </button>
                  <button v-else-if="parseInt(stage) === 2" class="font-size-12 btn btn-success float-right">
                    <JsonExcel
                      style="cursor: pointer"
                      :data="filtered"
                      :fields="jsonFields"
                      :name="`Mid_Year_Self_Assessment_Report(${fy}_${locationName}).xls`"
                    >
                      Export to Excel
                    </JsonExcel>
                  </button>
                  <button v-else-if="parseInt(stage) === 3" class="font-size-12 btn btn-success float-right">
                    <JsonExcel
                      style="cursor: pointer"
                      :data="filtered"
                      :fields="jsonFields"
                      :name="`End_Of_Year_Self_Assessment_Report(${fy}_${locationName}).xls`"
                    >
                      Export to Excel
                    </JsonExcel>
                  </button>
                </div>

              </div>
              <div class="col-sm-12 col-md-6">
                <h4>
                  <strong v-if=" parseInt(stage) === 1"> Beginning of Year </strong>
                  <strong v-else-if="parseInt(stage) === 2"> Mid-Year </strong>
                  <strong v-else-if="parseInt(stage) === 3"> End of Year </strong> Self-assessment Report for
                  <span class="text-success">{{ locationName }}</span>
                  ({{ fy }})
                </h4>
                <p class="text-center text-info" v-if="resultStatus">Processing request...</p>

                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                ref="donor-table"
                bordered
                selectable
                hover
                :items="assessments"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                show-empty
                select-mode="single"
                @row-selected="selectRow"
              >
                <template #cell(status)="row">
                  <span
                    v-if="row.value === 'Pending Approval' "
                    class="badge badge-pill badge-warning"
                  >
                    Pending Approval
                  </span>
                  <span
                    v-else-if="row.value === 'Approved' "
                    class="badge badge-pill badge-success"
                  >
                    Approved
                  </span>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
